import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { XpoShellRoute } from '@xpo/ngx-core/shell';
import { map, Observable, of } from 'rxjs';
import { AppRouteAccess, AppRouteNames } from './core/constants/routes.const';
import { UserAccessService } from './core/services/user-access.service';

@Component({
  selector: 'eso-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  routes$: Observable<XpoShellRoute[]> = of([]);

  constructor(private userAccessService: UserAccessService, translate: TranslateService) {
    translate.setDefaultLang('en-US');
  }

  ngOnInit(): void {
    this.routes$ = this.userAccessService.isAdmin$.pipe(
      map((isAdmin) => {
        const routes: { [key: string]: XpoShellRoute } = {
          [AppRouteNames.alertsAndExceptions]: {
            label: 'Alerts and Exceptions',
            path: './alerts-and-exceptions',
          },
          [AppRouteNames.forms]: { label: 'Forms', path: './forms' },
          [AppRouteNames.partnerMaster]: {
            label: 'Partner Manager',
            path: './partner-manager',
          },
          [AppRouteNames.admin]: { label: 'Admin', path: './admin' },
          [AppRouteNames.userOnboarding]: {
            label: 'User Onboarding',
            path: './user-onboarding',
          },
          [AppRouteNames.webContentManagement]: {
            label: 'Web Content Management',
            path: './web-content-management',
          },
          [AppRouteNames.webDeveloperPortal]: {
            label: 'Web Developer Portal',
            path: './web-developer-portal',
          },
        };

        return Object.entries(routes)
          .filter(([key, value]) => {
            return AppRouteAccess[key as AppRouteNames]({
              isAdmin,
              isUat: this.userAccessService.isUat,
              isProd: this.userAccessService.isProd,
            });
          })
          .map(([key, value]) => value);
      })
    );
  }
}
