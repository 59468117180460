import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { XpoLtlAuthFirestoreGuard } from '@xpo-ltl/ngx-ltl-auth/firestore';
import { AppRouteNames } from './core/constants/routes.const';
import { UserRouteAccessGuard } from './core/guards/user-route-access.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppRouteNames.alertsAndExceptions,
  },
  {
    path: AppRouteNames.alertsAndExceptions,
    canLoad: [XpoLtlAuthFirestoreGuard],
    canActivate: [UserRouteAccessGuard],
    loadChildren: () =>
      import('./alerts-and-exceptions/alerts-and-exceptions.module').then((m) => m.AlertsAndExceptionsModule),
  },
  {
    path: AppRouteNames.forms,
    canLoad: [XpoLtlAuthFirestoreGuard],
    canActivate: [UserRouteAccessGuard],
    loadChildren: () => import('./forms/forms.module').then((m) => m.FormsModule),
  },
  {
    path: AppRouteNames.partnerMaster,
    canLoad: [XpoLtlAuthFirestoreGuard],
    canActivate: [UserRouteAccessGuard],
    loadChildren: () => import('./partner-manager/partner-manager.module').then((mod) => mod.PartnerManagerModule),
  },
  {
    path: AppRouteNames.admin,
    canLoad: [XpoLtlAuthFirestoreGuard],
    canActivate: [UserRouteAccessGuard],
    loadChildren: () => import('./admin/admin.module').then((mod) => mod.AdminModule),
  },
  {
    path: AppRouteNames.userOnboarding,
    canLoad: [XpoLtlAuthFirestoreGuard],
    canActivate: [UserRouteAccessGuard],
    loadChildren: () => import('./user-onboarding/user-onboarding.module').then((mod) => mod.UserOnboardingModule),
  },
  {
    path: AppRouteNames.webContentManagement,
    canLoad: [XpoLtlAuthFirestoreGuard],
    canActivate: [UserRouteAccessGuard],
    loadChildren: () =>
      import('./web-content-management/web-content-management.module').then((mod) => mod.WebContentManagementModule),
  },
  {
    path: AppRouteNames.webDeveloperPortal,
    canLoad: [XpoLtlAuthFirestoreGuard],
    canActivate: [UserRouteAccessGuard],
    loadChildren: () =>
      import('./web-developer-portal/web-developer-portal.module').then((mod) => mod.WebDeveloperPortalModule),
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
